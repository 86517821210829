import React, { useState, useEffect } from "react";
import { client } from "../sanity";
import "../App.scss";
const BlockContent = require("@sanity/block-content-to-react");

const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    undefined: (props) => {
      return null;
    },
  },
};

function Om() {
  const [about, setAbout] = useState({});

  useEffect(() => {
    const fetchPosts = () => {
      client
        .fetch(`*[_type == $type][0]`, {
          type: "league",
        })
        .then((res) => {
          console.log("RES", res);
          setAbout(res.about);
        })
        .catch((err) => {
          console.log("err", err);
          console.error("Oh no, error occured: ", err);
        });
    };
    fetchPosts();
  }, []);

  return (
    <div className="om">
      {about && <BlockContent blocks={about} serializers={serializers} />}
    </div>
  );
}

export default Om;
